
.custom-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 10px;
}

.pointer {
    cursor: pointer;
}

.card-container {
    width: 550px;
    height: 150px;
    background-color: red;

    &:hover {
        cursor: pointer;
    }
}

.main-desc {
    padding: 0 20px 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    height: 70%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.text-desc {
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
